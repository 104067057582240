
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonusEach from "@/mixins/mixinBonusEach";
  import global from "@/vuex/bonus/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bonus/manager";
  import header from "@/vuex/header";
  import BonuseachTableNobonus from "@/components/bonuseach/table/Nobonus.vue";
  import CommonProgress from "@/components/common/Progress.vue";
  import BonuseachTableNocontentlist from "@/components/bonuseach/table/Nocontentlist.vue";
  import BonuseachList from "@/components/bonuseach/List.vue";

  @Component({
    components: {
      BonuseachTableNobonus,
      CommonProgress,
      BonuseachTableNocontentlist,
      BonuseachList,
    },
  })
  export default class Main extends Mixins(mixinBonusEach) {}
