
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonusEach from "@/mixins/mixinBonusEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";
  import CommonText from "@/components/common/Text.vue";
  import CommonAllowance from "@/components/common/Allowance.vue";

  @Component({
    components: {
      CommonText,
      CommonAllowance,
    },
  })
  export default class Main extends Mixins(mixinBonusEach) {
    get deduction_padding() {
      let remain = 20;
      remain -= 7; //基本であるもの
      this.saved_bonus_deductions.forEach((bonus_deduction) => {
        if (this.each_bonus_payment.each_bonus_deductions[bonus_deduction.id]) remain--;
      });
      return Math.max(0, remain);
    }
  }
