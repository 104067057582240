
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonusEach from "@/mixins/mixinBonusEach";

  @Component({
    components: {},
  })
  export default class Main extends Mixins(mixinBonusEach) {
    get deduction_padding() {
      let remain = 20;
      remain -= 7; //基本であるもの
      if (this.each_bonus_payment.is_valid_fixed_tax_reduction) remain--;
      this.saved_bonus_deductions.forEach((bonus_deduction) => {
        if (this.each_bonus_payment.each_bonus_deductions[bonus_deduction.id]) remain--;
      });
      return Math.max(0, remain);
    }
  }
