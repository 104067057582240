import { Component, Vue, Mixins, Watch } from "vue-property-decorator";
import monthly from "@/vuex/bonus_each/monthly";
import global from "@/vuex/bonus_each/global";
import * as util from "@/util";
import utilMixins from "@/mixins";
import manager from "@/vuex/bonus_each/manager";
import { EmployeeHeaderData, BranchHeaderData } from "@/typesold/header";

@Component
export default class mixinBonusEach extends Mixins(utilMixins) {
  changed_disp_num: boolean = false;

  get is_set_global(): boolean {
    return global.data[this.bonus_info_id] && global.data[this.bonus_info_id].is_set;
  }
  get is_set_monthly(): boolean {
    return (
      monthly.data[this.bonus_info_id] &&
      monthly.data[this.bonus_info_id][this.employee_id] &&
      monthly.data[this.bonus_info_id][this.employee_id].is_set
    );
  }

  get is_valid_global(): boolean {
    return global.data[this.bonus_info_id] && global.data[this.bonus_info_id].is_valid;
  }
  get is_valid_monthly(): boolean {
    return (
      monthly.data[this.bonus_info_id] &&
      monthly.data[this.bonus_info_id][this.employee_id] &&
      monthly.data[this.bonus_info_id][this.employee_id].is_valid
    );
  }

  async get_global() {
    if (this.param_bonus_info_id == 0) await global.fetch({ bonus_info_id: 0, auth: this.s_auth });
    else await global.fetch({ bonus_info_id: this.bonus_info_id, auth: this.s_auth });
  }
  async get_monthly() {
    await monthly.fetch({
      branch_id: this.viewing_branch,
      employee_id: this.employee_id,
      bonus_info_id: this.global.bonus_info.id,
    });
  }

  get global() {
    if ((!this.is_set_global || !this.is_valid_global) && this.bonus_info_id !== 0)
      this.get_global();
    if (this.is_set_global) return global.data[this.bonus_info_id];
  }
  get monthly() {
    if (!this.is_set_global || !this.is_valid_global)
      this.get_global(); //get_monthlyでglobalを使用するため
    else if (!this.is_set_monthly || !this.is_valid_monthly) this.get_monthly();
    if (this.is_set_monthly) return monthly.data[this.bonus_info_id][this.employee_id];
  }

  get employee_id() {
    if (this.is_manager) {
      return this.query.employee_id;
    } else {
      return Number(this.s_employee_id);
    }
  }
  set employee_id(val) {
    this.query_push = { employee_id: val };
  }

  get company() {
    return this.global.company;
  }
  get branch() {
    return this.monthly.branch;
  }
  get employee() {
    return this.monthly.employee;
  }
  get bonus_info() {
    return this.global.bonus_info;
  }

  get each_attendance() {
    return this.monthly.each_attendance;
  }
  get each_bonus_payment() {
    return this.monthly.each_bonus_payment;
  }

  get saved_bonus_allowances() {
    return this.global.saved_bonus_allowances;
  }
  get saved_bonus_deductions() {
    return this.global.saved_bonus_deductions;
  }
  get saved_bonus_other_allowances() {
    return this.global.saved_bonus_other_allowances;
  }

  get bank1_name() {
    return this.monthly.bank1_name;
  }
  get bank2_name() {
    return this.monthly.bank2_name;
  }

  //複数componentにInputが分かれているので、Mixinに統合した
  get tmp_each_bonus_payment() {
    return this.m.each_bonus_payment;
  }
  set tmp_each_bonus_payment(val) {
    this.m.setEachBonusPayment({ each_bonus_payment_object: val });
  }
  change_each_bonus_allowance(id: number, val) {
    this.m.setEachBonusAllowance({ id, val });
  }
  change_each_bonus_deduction(id: number, val) {
    this.m.setEachBonusDeduction({ id, val });
  }
  change_each_bonus_other_allowance(id: number, val) {
    this.m.setEachBonusOtherAllowance({ id, val });
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch;
    if (branch_id === undefined) {
      branch_id = Number(util.getLocalStorage("branch_id"));
      this.query = { viewing_branch: branch_id };
    }
    return branch_id;
  }
  set viewing_branch(val) {
    this.query = { viewing_branch: val };
  }

  get tab() {
    var tab = this.query.bonus_tab;
    if (tab === undefined) {
      tab = "all";
      this.query = { bonus_tab: tab };
    }
    return tab;
  }
  set tab(val) {
    this.query_push = { bonus_tab: val };
  }

  get m() {
    return manager;
  }

  get todayobj() {
    return new Date();
  }

  get dateobj() {
    return util.date2obj(this.bonus_info_id + "-01");
  }
  get bonus_info_id() {
    if (this.param_bonus_info_id == 0) {
      return global.latest_bonus_info_id;
    } else {
      return this.param_bonus_info_id;
    }
  }
  set bonus_info_id(val) {
    this.param_bonus_info_id = val;
  }

  get prev_bonus_info_id() {
    if (this.global) return this.global.prev_bonus_info_id;
  }
  get next_bonus_info_id() {
    if (this.global) return this.global.next_bonus_info_id;
  }

  get param_bonus_info_id() {
    var bonus_info_id = this.query.bonus_info_id;
    if (bonus_info_id === undefined) {
      bonus_info_id = 0;
      this.query = { bonus_info_id };
    }
    return bonus_info_id;
  }
  set param_bonus_info_id(val) {
    if (val != this.param_bonus_info_id) {
      manager.cancelAll();
      this.query_push = { bonus_info_id: val };
    }
  }

  get lock_type() {
    return this.monthly.lock_type;
  }
  get prev_employee_id() {
    return this.monthly.prev_employee_id;
  }
  get next_employee_id() {
    return this.monthly.next_employee_id;
  }

  get editable_bonus() {
    let header = this.header as BranchHeaderData;
    return this.s_auth == 2 || (this.s_auth == 1 && header.auth_bonus == 2);
  }

  get show_zero() {
    let header = this.header as EmployeeHeaderData;
    return this.is_manager || header.show_zero;
  }

  get show_custom() {
    return this.s_auth != 0;
  }

  get mode() {
    if (this.is_manager || !this.is_sp) {
      return 0;
    }
    return this.m.mode;
  }
  set mode(val) {
    util.setLocalStorage("bonus_each_mode", val);
    manager.setMode(val);
  }
}
