
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinBonusEach) {
  get close_started_on() {
    return util.formatDate(util.date2obj(this.bonus_info.close_started_on), 'yyyy年mm月dd日');
  }
  get close_ended_on() {
    return util.formatDate(util.date2obj(this.bonus_info.close_ended_on), 'yyyy年mm月dd日'); 
  }
}
