
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import dialog from "@/vuex/dialog";

@Component({
  components: {
  }
})
export default class Monthchanger extends Mixins(mixinBonusEach) {
}
