
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import global from "@/vuex/bonus/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/bonus/manager";
import header from "@/vuex/header";
import BonuseachTableNobonus from "@/components/bonuseach/table/Nobonus.vue";
import CommonProgress from "@/components/common/Progress.vue";
import BonuseachHead from "@/components/bonuseach/Head.vue";
import BonuseachTableNocontent from "@/components/bonuseach/table/Nocontent.vue";
import BonuseachNormal from "@/components/bonuseach/Normal.vue";

@Component({
  components: {
    BonuseachTableNobonus,
    CommonProgress,
    BonuseachHead,
    BonuseachTableNocontent,
    BonuseachNormal,
  }
})
export default class Main extends Mixins(mixinBonusEach) {
  
}
