
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinBonusEach from '@/mixins/mixinBonusEach';
import BonuseachMonthchanger from "@/components/bonuseach/Monthchanger.vue";
import CommonToggle from "@/components/common/Toggle.vue";
import BonuseachNormalmain from "@/components/bonuseach/Normalmain.vue";
import BonuseachListmain from "@/components/bonuseach/Listmain.vue";

@Component({
  components: {
    BonuseachMonthchanger,
    CommonToggle,
    BonuseachNormalmain,
    BonuseachListmain,
  }
})
export default class Bonus_each extends Mixins(mixinBonusEach) {
  get mode_value() {
    return this.mode;
  }
  set mode_value(val) {
    if (val) {
      this.mode = 1;
    } else {
      this.mode = 0;
    }
  }
}
