
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import dialog from "@/vuex/dialog";
import CommonPager from "@/components/common/Pager.vue";

@Component({
  components: {
    CommonPager,
  }
})
export default class Monthchanger extends Mixins(mixinBonusEach) {
  get monthstr() {
    if (this.global)
      return util.$t('paid_on', {year: util.date2year(this.bonus_info.paid_on), month: util.date2month(this.bonus_info.paid_on), day: util.date2date(this.bonus_info.paid_on)});
    else
      return '';
  }

  prev_bonus() {
    this.param_bonus_info_id = this.prev_bonus_info_id;
  }
  next_bonus() {
    this.param_bonus_info_id = this.next_bonus_info_id;
  }
}
