
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Head extends Mixins(mixinBonusEach) {
 
  get monthstr() {
    return util.formatDate(util.date2obj(this.bonus_info.paid_on), 'yyyy年mm月dd日');
  }

  
  prev_bonus() {
    this.bonus_info_id = this.prev_bonus_info_id;
  }
  next_bonus() {
    this.bonus_info_id = this.next_bonus_info_id;
  }

}
