
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinBonusEach) {
  get bank_padding() {
    var remain = 2;
    if (this.employee.bank1_id && this.employee.branch1_id)
      remain--;
    if (this.company.is_bank2 == 1 && this.employee.bank2_id && this.employee.branch2_id)
      remain--;
    return remain;
  }
}
