
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonText,
  }
})
export default class Main extends Mixins(mixinBonusEach) {
}
