
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonusEach from "@/mixins/mixinBonusEach";
  import global from "@/vuex/bonus/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/bonus/manager";
  import header from "@/vuex/header";
  import BonuseachTableHead from "@/components/bonuseach/table/Head.vue";
  import BonuseachTableAttendance from "@/components/bonuseach/table/Attendance.vue";
  import BonuseachTableInputallowance from "@/components/bonuseach/table/Inputallowance.vue";
  import BonuseachTableAllowance from "@/components/bonuseach/table/Allowance.vue";
  import BonuseachTableInputdeduction from "@/components/bonuseach/table/Inputdeduction.vue";
  import BonuseachTableDeduction from "@/components/bonuseach/table/Deduction.vue";
  import BonuseachTableInputotherallowance from "@/components/bonuseach/table/Inputotherallowance.vue";
  import BonuseachTableOtherallowance from "@/components/bonuseach/table/Otherallowance.vue";
  import BonuseachTableRealpay from "@/components/bonuseach/table/Realpay.vue";
  import BonuseachTableBank from "@/components/bonuseach/table/Bank.vue";
  import BonuseachTableInputcash from "@/components/bonuseach/table/Inputcash.vue";
  import BonuseachTableCash from "@/components/bonuseach/table/Cash.vue";
  import BonuseachTableOthers from "@/components/bonuseach/table/Others.vue";
  import BonuseachTableInputcomment from "@/components/bonuseach/table/Inputcomment.vue";
  import BonuseachTableComment from "@/components/bonuseach/table/Comment.vue";

  @Component({
    components: {
      BonuseachTableHead,
      BonuseachTableAttendance,
      BonuseachTableInputallowance,
      BonuseachTableAllowance,
      BonuseachTableInputdeduction,
      BonuseachTableDeduction,
      BonuseachTableInputotherallowance,
      BonuseachTableOtherallowance,
      BonuseachTableRealpay,
      BonuseachTableBank,
      BonuseachTableInputcash,
      BonuseachTableCash,
      BonuseachTableOthers,
      BonuseachTableInputcomment,
      BonuseachTableComment,
    },
  })
  export default class Main extends Mixins(mixinBonusEach) {}
