
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinBonusEach from "@/mixins/mixinBonusEach";
  import global from "@/vuex/payment/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/payment/manager";
  import header from "@/vuex/header";
  import CommonDate from "@/components/common/Date.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonDate,
      CommonButton,
    },
  })
  export default class Head extends Mixins(mixinBonusEach) {
    @Watch("is_edit")
    on_change_is_edit(newval, oldval) {
      if (newval && !oldval) {
        this.tmp_each_bonus_payment = {
          each_bonus_allowances: util.deep_copy(this.each_bonus_payment.each_bonus_allowances),
          each_bonus_deductions: util.deep_copy(this.each_bonus_payment.each_bonus_deductions),
          each_bonus_other_allowances: util.deep_copy(
            this.each_bonus_payment.each_bonus_other_allowances,
          ),

          money: this.each_bonus_payment.money,

          health_ins: this.each_bonus_payment.health_ins,
          care_ins: this.each_bonus_payment.care_ins,
          pension_ins: this.each_bonus_payment.pension_ins,
          emp_ins: this.each_bonus_payment.emp_ins,
          social_ins_adj: this.each_bonus_payment.social_ins_adj,
          income_tax: this.each_bonus_payment.income_tax,
          fixed_tax_reduction: this.each_bonus_payment.is_valid_fixed_tax_reduction
            ? this.each_bonus_payment.fixed_tax_reduction
            : null,

          paid_cash: this.each_bonus_payment.paid_cash,

          yearend_adj: this.each_bonus_payment.yearend_adj,
          settlement: this.each_bonus_payment.settlement,

          custom_paid_on: this.each_bonus_payment.custom_paid_on
            ? this.each_bonus_payment.custom_paid_on
            : this.bonus_info.paid_on,

          comment: this.each_bonus_payment.comment,
        };
      }
    }

    prev_employee() {
      this.employee_id = this.prev_employee_id;
    }
    next_employee() {
      this.employee_id = this.next_employee_id;
    }

    edit_each_bonus_payment() {
      this.m.editEachBonusPayment();
    }
    update_each_bonus_payment() {
      this.m.updateEachBonusPayment({
        bonus_info_id: this.bonus_info_id,
        employee_id: this.employee_id,
        employee: this.employee,
        saved_bonus_allowances: this.saved_bonus_allowances,
        saved_bonus_deductions: this.saved_bonus_deductions,
        saved_bonus_other_allowances: this.saved_bonus_other_allowances,
      });
    }

    get monthstr() {
      return util.formatDate(
        util.date2obj(
          this.each_bonus_payment.custom_paid_on
            ? this.each_bonus_payment.custom_paid_on
            : this.bonus_info.paid_on,
        ),
        "yyyy年mm月dd日",
      );
    }

    prev_bonus() {
      this.bonus_info_id = this.prev_bonus_info_id;
    }
    next_bonus() {
      this.bonus_info_id = this.next_bonus_info_id;
    }

    get is_edit() {
      return this.m.edit_each_bonus_payment_flag;
    }
  }
