
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinBonusEach from '@/mixins/mixinBonusEach';
import global from "@/vuex/payment/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/payment/manager";
import header from "@/vuex/header";

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinBonusEach) {
  get allowance_padding() {
    var remain = 19;
    remain -= 1;//基本であるもの
    console.log(this.each_bonus_payment.each_bonus_allowances);
    this.saved_bonus_allowances.forEach(bonus_allowance => {
      if (this.each_bonus_payment.each_bonus_allowances[bonus_allowance.id])
        remain--;
    });
    return Math.max(0, remain);
  }
}
